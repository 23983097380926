import React from "react"
import { Link } from "gatsby"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    
    return (
      <div>
        <nav class="bg-gray-800">
          <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between h-16">
              <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div class="flex-shrink-0 flex items-center text-white">
                <Link to={'/'} className="shadow-none">SO Tutorials </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <main>{children}</main>
        <footer class="bg-gray-800">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-8 text-white">
          This modified text is an extract of the original Stack Overflow Documentation created by the contributors and released under CC BY-SA 3.0
This website is not affiliated with Stack Overflow
          </div>
        </footer>
        <script async defer data-website-id="87f7eaa9-0a64-4f0d-9c3a-b04ce6a38113" src="https://umami.parthpatel.net/umami.js"></script>
      </div>
    )
  }
}

export default Layout
